import { type Config } from 'tailwindcss'
import defaultTheme from 'tailwindcss/defaultTheme'

export const extendedTheme = {
	colors: {
		border: 'hsl(var(--border))',
		'border-highlight': 'hsl(var(--border-highlight))',
		separator: 'hsla(var(--separator))',
		background: {
			DEFAULT: 'hsl(var(--background))',
			highlight: 'hsla(var(--background-highlight))',
		},
		foreground: {
			DEFAULT: 'hsl(var(--foreground))',
			placeholder: 'hsla(var(--foreground-placeholder))',
			muted: 'hsl(var(--foreground-muted))',
			destructive: 'hsl(var(--foreground-destructive))',
		},
		'navigation-foreground': {
			DEFAULT: 'hsl(var(--navigation-foreground))',
			hover: 'hsl(var(--navigation-foreground-hover))',
			active: 'hsl(var(--navigation-foreground-active))',
		},
		dialog: {
			background: 'hsl(var(--dialog-background))',
			foreground: 'hsl(var(--dialog-foreground))',
			border: 'hsl(var(--dialog-border))',
		},
		shortcut: {
			background: 'hsl(var(--shortcut-background))',
			foreground: 'hsl(var(--shortcut-foreground))',
		},
		tooltip: {
			background: 'hsl(var(--tooltip-background))',
			foreground: 'hsl(var(--tooltip-foreground))',
			border: 'hsl(var(--tooltip-border))',
		},
		'note-card': {
			background: 'hsl(var(--note-card-background))',
		},
		'list-card': {
			background: 'hsl(var(--list-card-background))',
		},
		menu: {
			background: 'hsl(var(--menu-background))',
			foreground: 'hsl(var(--menu-foreground))',
		}
	},
	fontFamily: {
		mono: ['var(--font-mono)', ...defaultTheme.fontFamily.mono],
		sans: ['var(--font-sans)', ...defaultTheme.fontFamily.sans],
		serif: [...defaultTheme.fontFamily.serif],
	},
	fontWeight: {
		extralight: '200',
		light: '300',
		normal: '400',
		medium: '500',
		semibold: '600',
		bold: '700',
	},
	fontSize: {
		base: 'var(--font-size-base)',
	},
	keyframes: {
		"accordion-down": {
			from: { height: "0" },
			to: { height: "var(--radix-accordion-content-height)" },
		},
		"accordion-up": {
			from: { height: "var(--radix-accordion-content-height)" },
			to: { height: "0" },
		},
		fadeIn: {
			'0%': { opacity: '0' },
			'100%': { opacity: '1' },
		},
	},
	animation: {
		"accordion-down": "accordion-down 0.2s ease-out",
		"accordion-up": "accordion-up 0.2s ease-out",
		'fade-in': 'fadeIn 0.3s ease-out',
	},
	padding: {
		'safe-top': 'env(safe-area-inset-top)',
		'safe-bottom': 'env(safe-area-inset-bottom)',
		'safe-left': 'env(safe-area-inset-left)',
		'safe-right': 'env(safe-area-inset-right)',
	},
} satisfies Config['theme']
